const DROPDOWN_ACTIVE_CLASS = 'is-active';
const DROPDOWN_BTN_QUERY = '.js_dropdown-btn';
const DROPDOWN_LIST_QUERY = '.js_dropdown-list';

export default class Dropdown {
	constructor(element) {
		this.selector = element;
		this.dropdownBtn = this.selector.querySelector(DROPDOWN_BTN_QUERY);
		this.dropdownList = this.selector.querySelector(DROPDOWN_LIST_QUERY);
		this.listSecondDropdownBtn = this.dropdownList.querySelectorAll(DROPDOWN_BTN_QUERY);

		this.bindEvents();
	}

	bindEvents = () => {
		if(!this.dropdownBtn || !this.dropdownList) {
			return;
		}
		this.dropdownBtn.addEventListener('click', () => {
			if(this.selector.classList.contains(DROPDOWN_ACTIVE_CLASS)) {
				this.closeDropdown()
			} else {
				this.openDropdown();
			}

		});
		document.addEventListener('click', (event) => {
			if (!event.target.closest(DROPDOWN_BTN_QUERY)) {
				this.closeDropdown();
				this.dropdownList.style.overflow = '';
			}
		});
		[...this.listSecondDropdownBtn].forEach(item => {
			item.addEventListener('click', () => {
				[...this.selector.querySelectorAll('.pns-dropdown')].forEach(dropdownELM => {
					if(!dropdownELM.contains(item)) {
						dropdownELM.classList.remove(DROPDOWN_ACTIVE_CLASS);
						dropdownELM.querySelector(DROPDOWN_LIST_QUERY).style.height = '';
					}
				})
				this.dropdownList.style.overflow = 'visible';
			})
		})
	}

	openDropdown = () => {
		this.selector.classList.add(DROPDOWN_ACTIVE_CLASS);
		this.dropdownList.style.height = this.dropdownList.scrollHeight + 'px';
	}

	closeDropdown = () => {
		this.selector.classList.remove(DROPDOWN_ACTIVE_CLASS);
		this.dropdownList.style.height = '';
	}
}