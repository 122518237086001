function isMobileViewport() {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return w <= 992;
}

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

const MODAL_TRANSITION_TIME = 400;
let isOpenModal = false;

class Modal {
  constructor(element, callBackCloseModal, noBackdrop = false, closeOnClickOutside = true) {
    this.selector = element;
    this.modal = this._getTarget();
    if (!this.modal) {
      this.modal = this.selector;
    }
    this.callBackCloseModal = callBackCloseModal;
    this.noBackdrop = noBackdrop;
    this.closeOnClickOutside = closeOnClickOutside;
    this.headerWrapper = document.querySelector('.js_header-wrapper');
    this.body = document.body;
    this.close = [...this.modal.querySelectorAll('.js-close-modal')];
    this.modalInner = this.modal.querySelector('.modal-inner');
    this.bindEvent = this.selector.dataset.bindEvent === 'true';
    this.timeout = null;
    this._listeners();
    // this._closeModal = this._closeModal.bind(this);
    // this._revealModal = this._revealModal.bind(this);
  }

  _listeners = () => {
    window.addEventListener('keydown', this._handleKeyDown);
    this.modal.addEventListener('transitionend', this._revealModal, false);
    this.modalInner.addEventListener('transitionend', this._closeModal, false);
    this.close.forEach((el) => {
      el.addEventListener('click', this.hideModal, false);
    });

    if (this.closeOnClickOutside) {
      this.modal.addEventListener('click', this._backdropClose, false);
    }
    if (this.bindEvent) {
      this.selector.addEventListener('click', () => {
        this.openModal();
      });
    }
  };

  rebindEvent = () => {
    if (this.modal) {
      this.close = [...this.modal.querySelectorAll('.js-close-modal')];
      this.close.forEach((el) => {
        el.addEventListener('click', this.hideModal, false);
      });
    }
  };

  openModal = () => {
    if (isOpenModal) {
      setTimeout(() => {
        this._openModal();
      }, MODAL_TRANSITION_TIME);
    } else {
      this._openModal();
    }
  };

  _openModal = () => {
    isOpenModal = true;
    document.body.classList.add('modal-body');
    if (this.noBackdrop) {
      document.body.classList.add('no-back-drop');
    }
    if (!isMobileViewport()) {
      this._disableScroll();
    }
    this.modal.classList.add('modal-visible');
  };

  hideModal = () => {
    // const modalOpen = document.querySelector('.modal.modal-visible');
    const modalOpen = this.modal;
    modalOpen.querySelector('.modal-inner').classList.remove('modal-reveal');
    document.body.classList.add('modal-fadeOut');
    const documentBody = document.body;
    if (!documentBody.dataset.addedModalTransition) {
      documentBody.dataset.addedModalTransition = true;
      documentBody.addEventListener('transitionend', this._removeModalBody, false);
    }

    if (!this.timeout) {
      this.timeout = clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.callBackCloseModal && this.callBackCloseModal();
    }, 200);
  };

  forceHideModal = () => this.hideModal();

  _getTarget = () => {
    const { dataset } = this.selector;
    return document.getElementById(dataset.modal);
  };

  _handleKeyDown = (e) => {
    if (e.keyCode === 27 && document.body.classList.contains('modal-body')) {
      this.hideModal();
    }
  };

  _backdropClose = (el) => {
    if (!el.target.classList.contains('modal-visible')) {
      return;
    }

    this.hideModal();
  };

  _closeModal = (el) => {
    if (el.propertyName === 'opacity' && !el.target.classList.contains('modal-reveal') && el.target.classList.contains('modal-inner')) {
      const modalId = document.querySelector('.modal.modal-visible');
      if (modalId) {
        modalId.classList.remove('modal-visible');
      }
    }
  };

  _revealModal = (el) => {
    if (el.propertyName === 'opacity' && el.target.classList.contains('modal-visible')) {
      el.target.querySelector('.modal-inner').classList.add('modal-reveal');
    }
  };

  _removeModalBody = (el) => {
    if (el.propertyName === 'opacity' && el.target.classList && el.target.classList.contains('modal') && !el.target.classList.contains('modal-visible')) {
      isOpenModal = false;
      document.body.classList.remove('modal-body');
      document.body.classList.remove('modal-fadeOut');
      document.body.classList.remove('no-back-drop');
      if (!isMobileViewport()) {
        this._enableScroll();
      }
    }
  };

  _disableScroll = () => {
    const scrollbarWidth = `${getScrollbarWidth()}px`;
    if (this.headerWrapper) {
      this.headerWrapper.style.paddingRight = `${scrollbarWidth}`;
    }
    // if (this.isModalOverFlow()) {
    //   return;
    // }
    this.body.style.overflow = 'hidden';
    this.body.style.paddingRight = `${0}`;
  };

  _enableScroll = () => {
    if (this.headerWrapper) {
      this.headerWrapper.style.removeProperty('padding-right');
    }
    // if (this.isModalOverFlow()) {
    // return;
    // }
    this.body.style.removeProperty('overflow');
    this.body.style.removeProperty('padding-right');
    setTimeout(() => {
      this.body.style.removeProperty('overflow');
      this.body.style.removeProperty('padding-right');
    }, 10); // 10ms added for CSS transition in Firefox which doesn't like overflow:auto
  };

  isModalOverFlow = () => window.innerHeight > document.body.clientHeight;

  updateHeaderTitle = (title) => {
    const titleElement = this.modal.querySelector('.js_modal__title');
    titleElement.innerText = title;
  };
}

export default Modal;
