export const MOCK_TABLE_DATA = {
    "header": [['app', ''], ['', '']],
    "rows": [
        ['', '', '', ''],
    ],
};

export const SHORTCUTS = [
    {
        text: 'Today',
        onClick: () => {
            return [new Date(), new Date()];
        }
    }
    ,
    {
        text: 'Yesterday',
        onClick: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return [date, new Date()];
        },
    },
    {
        text: 'Last week',
        onClick: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return [date, new Date()];
        },
    },
    {
        text: 'Last month',
        onClick: () => {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            return [date, new Date()];
        },
    },
    {
        text: 'Last year',
        onClick: () => {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 1);
            return [date, new Date()];
        },
    },
]